app-json-form-group {
	display: flex;
	flex-direction: column;
	gap: var(--groupui-spacing-7);
}
app-json-form-group {
	display: flex;
	flex-direction: column;
	gap: var(--groupui-spacing-4);
}
