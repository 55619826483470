// SOURCE https://loading.io/css/
// LICENSE CC0

.btr-spinner {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
}

.btr-spinner div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 24px;
	height: 24px;
	margin: 0;
	border: 2px solid #fff;
	border-radius: 50%;
	animation: btr-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}

.btr-spinner-primary-color div {
	border: 2px solid rgb(168, 173, 179);
	border-color: rgb(168, 173, 179) transparent transparent transparent;
}
.btr-spinner-primary-inverted-color div {
	border: 2px solid #fff;
	border-color: #fff transparent transparent transparent;
}

.btr-spinner div:nth-child(1) {
	animation-delay: -0.45s;
}
.btr-spinner div:nth-child(2) {
	animation-delay: -0.3s;
}
.btr-spinner div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes btr-spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.btr-loading-ellipsis {
	display: flex;
	position: relative;
	align-items: center;
}
.btr-loading-ellipsis div {
	position: absolute;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #fff;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.btr-loading-ellipsis-primary-color div {
	background: var(--groupui-vwag-color-petrol-800-primary);
}
.btr-loading-ellipsis-primary-inverted-color div {
	background: #fff;
}

.btr-loading-ellipsis div:nth-child(1) {
	left: 8px;
	animation: btr-loading-ellipsis1 0.6s infinite;
}
.btr-loading-ellipsis div:nth-child(2) {
	left: 8px;
	animation: btr-loading-ellipsis2 0.6s infinite;
}
.btr-loading-ellipsis div:nth-child(3) {
	left: 32px;
	animation: btr-loading-ellipsis2 0.6s infinite;
}
.btr-loading-ellipsis div:nth-child(4) {
	left: 56px;
	animation: btr-loading-ellipsis3 0.6s infinite;
}
@keyframes btr-loading-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes btr-loading-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes btr-loading-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}

// Loading is a MIT public codepen product
// https://codepen.io/jcoulterdesign/pen/bNxeKY

@mixin hole {
	border-radius: 100%;
	background: white;
	position: absolute;
}

/* Variables */

$cogTop: var(--groupui-vwag-color-petrol-800-primary);
$cogLeft: var(--groupui-vwag-color-petrol-800-primary);
$right: var(--groupui-vwag-color-petrol-800-primary);

/* Mixins */

@mixin center {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}

.loader {
	height: 100%;
	position: relative;
	margin: auto;
	width: 400px;
	&_cogs {
		z-index: -2;
		width: 100px;
		height: 100px;
		top: -120px !important;
		@include center;
		&__top {
			position: relative;
			width: 100px;
			height: 100px;
			transform-origin: 50px 50px;
			animation: rotate 10s infinite linear;
			@for $i from 1 through 3 {
				div:nth-of-type(#{$i}) {
					transform: rotate($i * 30deg);
				}
			}
			div.top_part {
				width: 100px;
				border-radius: 10px;
				position: absolute;
				height: 100px;
				background: $cogTop;
			}
			div.top_hole {
				width: 50px;
				height: 50px;
				@include hole;
				@include center;
			}
		}
		&__left {
			position: relative;
			width: 80px;
			transform: rotate(16deg);
			top: 28px;
			transform-origin: 40px 40px;
			animation: rotate_left 10s infinite reverse linear;
			left: -24px;
			height: 80px;
			@for $i from 1 through 3 {
				div:nth-of-type(#{$i}) {
					transform: rotate($i * 30deg);
				}
			}
			div.left_part {
				width: 80px;
				border-radius: 6px;
				position: absolute;
				height: 80px;
				background: $cogLeft;
			}
			div.left_hole {
				width: 40px;
				height: 40px;
				@include hole;
				@include center;
			}
		}
		&__bottom {
			position: relative;
			width: 60px;
			top: -50px;
			transform-origin: 30px 30px;
			animation: rotate_left 10.2s infinite linear;
			transform: rotate(4deg);
			left: 79px;
			height: 60px;
			@for $i from 1 through 3 {
				div:nth-of-type(#{$i}) {
					transform: rotate($i * 30deg);
				}
			}
			div.bottom_part {
				width: 60px;
				border-radius: 5px;
				position: absolute;
				height: 60px;
				background: $right;
			}
			div.bottom_hole {
				width: 30px;
				height: 30px;
				@include hole;
				@include center;
			}
		}
	}
}

/* Animations */

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes rotate_left {
	from {
		transform: rotate(16deg);
	}
	to {
		transform: rotate(376deg);
	}
}

@keyframes rotate_right {
	from {
		transform: rotate(4deg);
	}
	to {
		transform: rotate(364deg);
	}
}
